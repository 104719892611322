import React, { Suspense } from 'react';
import 'semantic-ui-css/semantic.min.css';
import Rajmain from './home/main';
import { withTranslation } from 'react-i18next';


function App() {
  return (
    <>
      <Suspense fallback="loading">
        <Rajmain></Rajmain>
      </Suspense>
    </>
  );
}

export default withTranslation('common')(App);
