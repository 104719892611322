import React, { Component, Suspense } from 'react';
import { Segment } from 'semantic-ui-react';
import './main.css';
import RajMenu from './menu';
import RajAddress from './address';
import RajCompanyTitle from './company';

export default class Rajmain extends Component {
    render() {
        return (
            <Segment centered textAlign='center' style={{ backgroundImage: `url(${'/landingimage.jpg'})`, height: '700px' }}>

                <RajMenu />
                <RajCompanyTitle />
                <RajAddress />

            </Segment>
        )
    }
}