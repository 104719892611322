import React from 'react';
import { Menu, Button, Input, Responsive, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function RajMenu() {
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Menu color='#01452c' fixed='top' inverted>
                <Menu.Item>
                    <Image src='/rajlogo.jpg' size='mini' circular />
                </Menu.Item>
                <Menu.Item as='a' active='true' name='home'>{t('menus.home')}</Menu.Item>
                <Menu.Item as='a' name='aboutus'>{t('menus.aboutus')}</Menu.Item>
                <Menu.Item as='a' name='thefarm'>{t('menus.thefarm')}</Menu.Item>
                <Menu.Item as='a' name='gallery'>{t('menus.gallery')}</Menu.Item>
                <Menu.Item as='a' name='products'>{t('menus.products')} </Menu.Item>
                <Menu.Item as='a' name='contactus'>{t('menus.contactus')}</Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button.Group>
                            <Button size='tiny' onClick={() => changeLanguage('ta')}>தமிழ்</Button>
                            <Button.Or text='or' />
                            <Button size='tiny' onClick={() => changeLanguage('en')}>English</Button>
                        </Button.Group>
                    </Menu.Item>
                    <Menu.Item>
                        <Input icon='search' placeholder={t('search')} />
                    </Menu.Item>
                </Menu.Menu>

            </Menu>
        </Responsive>
    );
}