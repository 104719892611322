import React from 'react';
import { Menu, Grid, List, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function RajAddress() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Grid>
                <Grid.Row centered columns={3}>
                    <Grid.Column textAlign='center'>
                        <Menu fluid vertical>
                            <Menu.Item><Icon name='marker' />{t('address1')}</Menu.Item>
                            <Menu.Item><Icon name='marker' />{t('address2')}</Menu.Item>
                            <Menu.Item><Icon name='mail' /><a href='mailto:rajkumarindustries@gmail.com'>{t('email')}</a></Menu.Item>
                            <Menu.Item><Icon name='phone' />{t('phone')}</Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* <Grid verticalAlign='middle' textAlign='center' >
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Menu style={{ backgroundColor: '' }}>
                            <Menu.Item>
                                <List>
                                    <List.Item icon='marker' content={t('address1')} />
                                    <List.Item icon='' content={t('address2')} />
                                    <List.Item
                                        icon='mail'
                                        content=
                                    />
                                    <List.Item
                                        icon='phone'
                                        content='93661 42789,  93676 42789,  93444 42789'
                                    />
                                </List>
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid> */}
        </>
    )
}