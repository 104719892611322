import React from 'react';
import { Label, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function RajCompanyTitle() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Label as='a' color='green' size='massive' style={{ fontSize: 35, marginTop: 50, fontWeight: 'bold' }} image>
                <img src='/rajlogo.jpg' size='small' />
                {t('title')}
            </Label>
            <Divider />
        </>
    )
}